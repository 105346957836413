import React from 'react';
import { TextField } from '@material-ui/core';

function LocalizationSearchComponent(props: { onSearchInputChanged: (text: string) => void; }): JSX.Element
{
    return (
        <TextField
            label="Search"
            onChange={ev => { ev.persist(); setTimeout(() => props.onSearchInputChanged(ev.target.value), 10); }}
            fullWidth
            margin="dense"
        />
    );
}

export default LocalizationSearchComponent;
