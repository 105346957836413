import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyIcon from '@material-ui/icons/Money';
import History from '@material-ui/icons/History';
import TranslateIcon from '@material-ui/icons/Translate';
import StatusIcon from '@material-ui/icons/Warning';
import { Omit } from '@material-ui/types';
import { Link } from 'react-router-dom';

type ChildrenType = { id: string; icon: JSX.Element; active?: boolean; link: string; };
export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> { }

const categoriesAdmin: { id: string, children: ChildrenType[]; }[] = [
    {
        id: 'Configuration',
        children: [
            { id: 'Users', icon: <PeopleIcon />, link: '/users' },
            { id: 'Game Configuration', icon: <SettingsIcon />, link: '/game-configuration' },
            { id: 'Bot Configuration', icon: <SettingsIcon />, link: '/bot-configuration' },
            { id: 'Matches', icon: <History />, link: '/matches' },
            { id: 'Taxes', icon: <MoneyIcon />, link: '/taxes' },
            { id: 'Localization', icon: <TranslateIcon />, link: '/localization' },
            { id: 'Status', icon: <StatusIcon />, link: '/status' },
            { id: 'Logout', icon: <PeopleIcon />, link: '/logout' }
        ],
    }
];

const categoriesGov: { id: string, children: ChildrenType[]; }[] = [
    {
        id: 'Configuration',
        children: [
            { id: 'Matches', icon: <History />, link: '/matches' },
            { id: 'Logout', icon: <PeopleIcon />, link: '/logout' }
        ],
    }
];

const styles = (theme: Theme) =>
    createStyles({
        categoryHeader: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        categoryHeaderPrimary: {
            color: theme.palette.common.white,
        },
        item: {
            paddingTop: 1,
            paddingBottom: 1,
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        },
        itemCategory: {
            backgroundColor: '#232f3e',
            boxShadow: '0 -1px 0 #404854 inset',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        firebase: {
            fontSize: 24,
            color: theme.palette.common.white,
        },
        itemActiveItem: {
            color: '#4fc3f7',
        },
        itemPrimary: {
            fontSize: 'inherit',
        },
        itemIcon: {
            minWidth: 'auto',
            marginRight: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
        },
    });

function CreateCategories(id: string, classes: Record<string, string>, children: ChildrenType[]): JSX.Element
{
    return <React.Fragment key={id}>
        <ListItem className={classes.categoryHeader}>
            <ListItemText classes={{ primary: classes.categoryHeaderPrimary, }}>
                {id}
            </ListItemText>
        </ListItem>
        {children.map(({ id: childId, icon, active, link }) => CreateCategoryMenuFields(link, childId, classes, active, icon))}
        <Divider className={classes.divider} />
    </React.Fragment>;
}

function CreateCategoryMenuFields(link: string | undefined, childId: string, classes: Record<string, string>, active: boolean | undefined, icon: JSX.Element): JSX.Element
{
    return (
        <Link to={link || '/'} key={childId}>
            <ListItem
                key={childId}
                button
                className={clsx(classes.item, active && classes.itemActiveItem)}
            >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemPrimary,
                    }}
                >
                    {childId}
                </ListItemText>
            </ListItem>
        </Link>
    );
}

function NavigatorAdmin(props: NavigatorProps)
{
    const { classes, ...other } = props;

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    JogaJoga
                </ListItem>
                {categoriesAdmin.map(({ id, children }) => CreateCategories(id, classes, children))}
            </List>
        </Drawer>
    );
}

function NavigatorGov(props: NavigatorProps)
{
    const { classes, ...other } = props;

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    JogaJoga
                </ListItem>
                {categoriesGov.map(({ id, children }) => CreateCategories(id, classes, children))}
            </List>
        </Drawer>
    );
}

export const NavigatorGovWithStyle = withStyles(styles)(NavigatorGov);
export default withStyles(styles)(NavigatorAdmin);
