import React from 'react';
import {Button, Container, Paper} from '@material-ui/core';
import {ColDef, DataGrid, RowData} from '@material-ui/data-grid';
import {firebase} from '../Firebase';
import {QuerySnapshot} from '../Types';

interface IState
{
    rows: RowData[];
    userId: string;
}


export type Transaction = {
    timestamp: { seconds: number; };
    amount?: number;
    ballNumber?: number;
    playerId: string;
    reason?: string;
    matchId?: string;
    reference_id?: string;
    ourAccountNumber?: string;
    destinationAccountHolderName?: string;
    currencyOfDestination?: string;
    currencyOfOrigin?: string;
    destinationBancSwiftCode?: string;
    destinationBankAccountNumber?: string;
    createdAt?: { seconds: number; };
    transferType?: string;
    terminal_transaction_id?: string;
    transaction_id?: string;
    fee?: string;
    entity_id?: string;
    terminal_id?: string;
    parameter_id?: string;
    period_end_datetime?: string;
    terminal_period_id?: string;
    period_start_datetime?: string;
    terminal_type?: string;
    id?: string;
    datetime?: string;
    product_id?: string;
    terminal_location?: string;
    period_id?: string;
    custom_fields?: string;
    details?: string;
};

function getQueryVariable(variable: string): string
{
    const query = window.location.search.substring(1);

    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++)
    {
        const pair = vars[i].split('=');

        if (pair[0] === variable)
        {
            return pair[1];
        }
    }

    return '';
}

export default class UserTransactions extends React.Component<unknown, IState> {

    columns: ColDef[] = [
        { field: 'id', headerName: 'Id', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'matchId', headerName: 'Match ID', width: 100 },
        { field: 'reason', headerName: 'Reason', width: 200 },
        { field: 'timestamp', headerName: 'Timestamp', width: 250 },
    ];
    state: IState = {
        rows: [],
        userId: ''
    };

    async retrieveAllTransactionForUser(userId: string): Promise<void>
    {
        const results: QuerySnapshot | void = await firebase.app().firestore().collection('transactions').where('playerId', '==', userId).get().catch(console.error);

        if (!results)
            return;

        const currentRows: RowData[] = [];

        for (const transactionDocument of results.docs)
            if (transactionDocument.exists)
            {
                const data: Transaction = transactionDocument.data() as Transaction;

                if (!data)
                    continue;

                currentRows.push({
                    id: transactionDocument.id || '',
                    amount: data.amount || '',
                    matchId: data.matchId || '',
                    reason: data.reason || '',
                    timestamp: new Date(data.timestamp.seconds * 1000).toUTCString()
                });
            }

        this.setState({ rows: currentRows });
    }

    async componentDidMount(): Promise<void>
    {
        const userId: string = getQueryVariable('user').trim();

        if (!userId)
            return console.error('Expected user parameter not found...');

        console.log('Getting transactions for user....', userId);

        this.setState({ userId });

        await this.retrieveAllTransactionForUser(userId);
    }

    async refreshData(event: React.FormEvent<HTMLFormElement>): Promise<void>
    {
        event.preventDefault();

        if (this.state.userId)
            await this.retrieveAllTransactionForUser(this.state.userId);
    }

    render(): JSX.Element
    {
        return (
            <Container component="main" style={{ minHeight: '100vh' }}>
                <Paper>
                    <form noValidate autoComplete="off" onSubmit={this.refreshData.bind(this)}>
                        <Button type="submit" fullWidth variant="contained" color="primary">Refresh</Button>
                    </form>
                </Paper>
                <DataGrid autoHeight rows={this.state.rows} columns={this.columns} pageSize={15} />
            </Container>
        );
    }
}
