import React, { FormEvent } from 'react';
import { Container, Paper, Button, TextField } from '@material-ui/core';
import { DataGrid, ColDef, RowData, CellParams, ValueFormatterParams } from '@material-ui/data-grid';
import { firebase } from '../Firebase';
import { DocumentSnapshot, QuerySnapshot } from '../Types';
import { RouteComponentProps } from 'react-router-dom';
import { firestore } from 'firebase';
import { UsersParticipantsButtonWithModal } from './UsersParticipantsButtonWithModal';


interface IState
{
    rows: RowData[];
    gameIdFilter: string;
    gameNumberFilter: string;
    selectedGameId: string;
    loadingPercentage: number;
}

export type OccupiedSlotsType = {
    playerId: string,
    ballNumber: number;
};
export type ActiveMatchResult = {
    id: string,
    occupiedSlots: OccupiedSlotsType[],
    status: string,
    expiration: number,
    createdAt: firestore.Timestamp,
    gameNumber: string,
    totalPrizePool: number,
    playerBall?: number;
    minimumPlayersRequired: number;
    bettingTiersReference: string;
    taxesReference: string;
};



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class GamesListing extends React.Component<RouteComponentProps<any>, IState> {

    private static GamesCache: { [matchId: string]: ActiveMatchResult; } = {};

    columns: ColDef[] = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'gameNumber', headerName: 'Number', width: 140 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'totalPrizePool', headerName: 'Prize Pool after Tax', width: 200 },
        { field: 'createdAt', headerName: 'Created At', width: 200, valueFormatter: (params: CellParams) => (params.value as Date)?.toDateString?.() },
        {
            field: 'Users', headerName: 'Users', width: 300, renderCell: (params: ValueFormatterParams): JSX.Element => (
                <UsersParticipantsButtonWithModal match={GamesListing.GamesCache[params.data.id]} />
            )
        }
    ];

    state: IState = {
        rows: [],
        gameIdFilter: '',
        gameNumberFilter: '',
        selectedGameId: '',
        loadingPercentage: 0
    };

    private unsubscribeEventListeners: (() => void) | null = null;

    onMatchReceived = (snapshot: QuerySnapshot) =>
    {
        for (const match of snapshot.docs.map((document: DocumentSnapshot) => Object.assign({ id: document.id }, document.data()) as ActiveMatchResult))
            GamesListing.GamesCache[match.id] = match;

        this.createOrUpdateRowsFromCache();
    };

    retrieveAllGames(): void
    {
        const unsubArchived: () => void = firebase.app().firestore().collection('archived_matches').onSnapshot(this.onMatchReceived);
        const unsubActive: () => void = firebase.app().firestore().collection('active_matches').onSnapshot(this.onMatchReceived);

        this.unsubscribeEventListeners = () =>
        {
            unsubArchived();
            unsubActive();
        };
    }

    createOrUpdateRowsFromCache(): void
    {
        const currentRows: RowData[] = [];

        for (const [matchId, match] of Object.entries(GamesListing.GamesCache))
            currentRows.push({
                id: matchId,
                gameNumber: match.gameNumber,
                status: match.status,
                totalPrizePool: match.totalPrizePool,
                createdAt: match.createdAt?.toDate()
            });

        this.setState({ rows: currentRows.sort((a, b) => b.createdAt - a.createdAt) });
    }

    async componentDidMount(): Promise<void>
    {
        this.retrieveAllGames();
        this.createOrUpdateRowsFromCache();
    }

    componentWillUnmount()
    {
        if(this.unsubscribeEventListeners != null) this.unsubscribeEventListeners();
    }


    private filterRows(event: FormEvent<HTMLFormElement>): void
    {
        if (event != null) event.preventDefault();

        const gameIdFilter: string = this.state.gameIdFilter.trim().toLowerCase();
        const gameNumberFilter: string = this.state.gameNumberFilter.trim().toLowerCase();

        if (!gameIdFilter && !gameNumberFilter)
            return this.createOrUpdateRowsFromCache();

        const currentRows: RowData[] = [];

        for (const [matchId, match] of Object.entries(GamesListing.GamesCache))
            if (this.isUserMatchForFilter(match, gameIdFilter, gameNumberFilter))
                currentRows.push({
                    id: matchId,
                    gameNumber: match.gameNumber,
                    status: match.status,
                    totalPrizePool: match.totalPrizePool
                });

        this.setState({ rows: currentRows });
    }

    private isUserMatchForFilter(gameData: ActiveMatchResult, gameIdFilter: string, gameNumberFilter: string)
    {
        return ((gameIdFilter && gameData.id?.toLowerCase()?.includes(gameIdFilter))
            || (gameNumberFilter && gameData.gameNumber?.toLowerCase()?.includes(gameNumberFilter)));
    }

    private resetFilter(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
    {
        if (event != null) event.preventDefault();

        this.setState({
            gameIdFilter: '',
            gameNumberFilter: ''
        });

        this.createOrUpdateRowsFromCache();
    }


    render(): JSX.Element
    {
        return (
            <Container component="main" style={{ minHeight: '100vh', minWidth: '90%' }}>
                <Paper>
                    <form style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px' }} noValidate autoComplete="off" onSubmit={this.filterRows.bind(this)}>
                        <TextField
                            id="id-filter"
                            value={this.state.gameIdFilter}
                            label="Game ID"
                            onChange={(event) => this.setState({ gameIdFilter: event.target.value })} />
                        <TextField
                            id="game-number-filter"
                            value={this.state.gameNumberFilter}
                            label="Game Number"
                            onChange={(event) => this.setState({ gameNumberFilter: event.target.value })} />
                        <Button type="submit" size="large" style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px', width: '9vw' }} variant="contained" color="primary">Filter</Button>
                        <Button size="large" style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px', width: '9vw' }} variant="contained" color="secondary" onClick={this.resetFilter.bind(this)}>Reset</Button>
                    </form>
                </Paper>
                <DataGrid autoHeight rows={this.state.rows} columns={this.columns} pageSize={15} />
            </Container >
        );
    }
}
