import React, { useEffect, useState } from 'react';
import { Chip, Paper } from '@material-ui/core';
import FilterTranslationKeyForm from './LocalizationSearchComponent';
import Translations, { KeyTranslationType } from './LocalizationComponent';
import { DocumentSnapshot, StateHook } from '../../Types';
import { firebase } from '../../Firebase';
import DoneIcon from '@material-ui/icons/Done';
import TranslationForm from './LocalizationForm';

export type LanguageStore = { languageData: KeyTranslationType, version: number; };

function getKeysThatMeetFilterCriteria(targetValue: string): string[]
{
    const filteredKeys: string[] = [];

    for (const [translationKey, translations] of Object.entries(TranslationCache))
        if (translationKey.toLocaleLowerCase().includes(targetValue))
            filteredKeys.push(translationKey);
        else
            for (const foreignTranslations of Object.values(translations))
                if (foreignTranslations.toLocaleLowerCase().includes(targetValue))
                {
                    filteredKeys.push(translationKey);
                    break;
                }

    return filteredKeys;
}

export let TranslationCache: KeyTranslationType = {};

function LocalizationContainer(): JSX.Element
{
    const [selectedKey, setselectedKey]: StateHook<string> = useState('');
    const [version, setVersion]: StateHook<number> = useState(0);
    const [isPanelExpended, setPanelExpended]: StateHook<boolean> = useState(false as boolean);
    const [isSuccessChipShown, setShowSuccessChip]: StateHook<boolean> = useState(false as boolean);
    const [isErrorChipShown, setShowErrorChip]: StateHook<boolean> = useState(false as boolean);
    const [keysToDisplay, setKeysToDisplay]: StateHook<string[]> = useState([] as string[]);

    const fetchTranslations = async () =>
    {
        const languageDocument: null | DocumentSnapshot = await firebase.firestore().collection('translations').doc('ActiveTranslations').get().catch(error => { console.error(error); return null; });

        const { languageData, version }: LanguageStore = (languageDocument?.data() as LanguageStore || {});

        if (!languageData)
            return;

        console.log(JSON.stringify(languageData));

        TranslationCache = languageData;

        setVersion(version);
        setKeysToDisplay(Object.keys(TranslationCache));
    };

    const onTranslationKeySelect = (key: string) =>
    {
        setPanelExpended(true);
        setselectedKey(key);
    };

    const onTranslationKeyDelete = (key: string) =>
    {
        delete TranslationCache[key];
        setKeysToDisplay(keysToDisplay.filter(displayedKey => displayedKey !== key));
    };

    const searchChanged = (targetValue: string) =>
    {
        targetValue = targetValue.toLowerCase();

        if (targetValue.length === 0)
            return setKeysToDisplay(Object.keys(TranslationCache));

        setKeysToDisplay(getKeysThatMeetFilterCriteria(targetValue));
    };

    const saveNewTranslatations = async () =>
    {
        const result: void | { error: unknown; } = await firebase.firestore().collection('translations').doc('ActiveTranslations').set({
            languageData: TranslationCache,
            version: version + 1
        }).catch(error => { console.error(error); return { error: error || {} }; });

        if (result && result.error)
            return setShowErrorChip(true);

        setShowSuccessChip(true);
        setVersion(version + 1);
        setselectedKey('');

        console.log('Saved');
    };

    useEffect(() => { fetchTranslations(); }, []);
    useEffect(() => setKeysToDisplay(Object.keys(TranslationCache)), [version]);
    useEffect(() => { isSuccessChipShown && setTimeout(() => setShowSuccessChip(false), 3000); }, [isSuccessChipShown]);
    useEffect(() => { isErrorChipShown && setTimeout(() => setShowErrorChip(false), 3000); }, [isErrorChipShown]);

    return (
        <>
            {isSuccessChipShown && <Chip size="medium" color="primary" label="Success!" deleteIcon={<DoneIcon />} />}
            {isErrorChipShown && <Chip size="medium" color="secondary" label="Something went wrong!" deleteIcon={<DoneIcon />} />}
            <Paper elevation={1}>
                <FilterTranslationKeyForm onSearchInputChanged={searchChanged} />
                <TranslationForm
                    selectedKey={selectedKey}
                    saveTranslations={saveNewTranslatations}
                    togglePanelExpended={() => setPanelExpended(!isPanelExpended)}
                    isPanelExtended={isPanelExpended} />
                <Translations
                    onTranslationKeyDelete={onTranslationKeyDelete}
                    onTranslationKeySelect={onTranslationKeySelect}
                    keysToDisplay={keysToDisplay.sort()}
                />
            </Paper></>
    );
}

export default LocalizationContainer;
