import { Grid, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { StateHook } from '../../Types';
import { SupportedLanguagesTags } from './LocalizationComponent';

type LocalizationInputProps = {
    language: SupportedLanguagesTags,
    initialTranslation: string;
    translationKey: string;
    onTranslationUpdated: (languageTag: SupportedLanguagesTags, translationText: string) => void;
};

function LocalizationTranslationInput(props: LocalizationInputProps): JSX.Element
{
    const [translationText, setTranslationText]: StateHook<string> = useState('');

    const onTextChanged = (newText: string) =>
    {
        setTranslationText(newText);
        props.onTranslationUpdated(props.language, newText);
    };

    useEffect(() => setTranslationText(props.initialTranslation || ''), [props.initialTranslation]);

    return (
        <Grid item xs={12} key={props.language}>
            <TextField
                type="text"
                label={props.language}
                fullWidth={true}
                value={translationText}
                disabled={props.translationKey.length === 0}
                placeholder={props.translationKey.length === 0 ? 'Key must not be empty' : ''}
                onChange={ev => onTextChanged(ev.target.value)}
                style={{ whiteSpace: 'pre-wrap' }}
                multiline
                variant="outlined"
            />
        </Grid>
    );
}

export default LocalizationTranslationInput;
