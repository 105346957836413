import React from "react";
import {DataGrid, RowData} from "@material-ui/data-grid";
import {Button, Container, Paper} from "@material-ui/core";
import firebase from "firebase";

interface IState
{
    
}

export default class UserLogout extends React.Component<unknown, IState> {

    async componentDidMount(): Promise<void>
    {
        await firebase.app().auth().signOut();
    }

    render(): JSX.Element
    {
        return (
            <></>
        );
    }
}