import React from 'react';
import Typography from '@material-ui/core/Typography';

function Content()
{
    return (
        <Typography color="textSecondary" align="center">
            Select an option from the menu on the left
        </Typography>
    );
}

export default Content;
