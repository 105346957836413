/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { List, Paper } from '@material-ui/core';

import LocalizationKey from './LocalizationKey';

export type SupportedLanguagesTags = 'en-US' | 'pt-PT';

export type TranslationLanguages = Record<SupportedLanguagesTags, string>;

export type KeyTranslationType = Record<string, TranslationLanguages>;

type Props = {
    onTranslationKeySelect: (key: string) => void;
    onTranslationKeyDelete: (key: string) => void;
    keysToDisplay: string[];
};

function Translations(props: Props): JSX.Element
{
    return (
        <Paper >
            <List component="nav">
                {props.keysToDisplay.map((element, index) => <LocalizationKey
                    key={index}
                    translationKey={element}
                    onTranslationKeySelect={key => props.onTranslationKeySelect(key)}
                    onTranslationKeyDelete={key => props.onTranslationKeyDelete(key)} />)}
            </List>
        </Paper>
    );

}

export default Translations;
