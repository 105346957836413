import React from 'react';
import { firebase } from './Firebase';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Paperbase, {PaperbaseGovWithStyle} from './Paperbase';
import SignIn from './SignIn';
import { DocumentSnapshot } from './Types';

interface IState
{
    isAuthorized: boolean;
    blah: boolean;
}

type PrivateAuthorizationState = { isAdmin: boolean, isSignedIn: boolean, isGov: boolean; };

export default class App extends React.Component<Record<string, never>, IState> {
    state: IState = { isAuthorized: false, blah: false };

    private privateState: PrivateAuthorizationState = {
        isAdmin: false,
        isSignedIn: false,
        isGov: false,
    };

    private unsubscribeHandler: firebase.Unsubscribe | undefined;

    private async handleUserAuthStateChanged(user: firebase.User | null): Promise<void>
    {
        if (!user)
        {
            this.privateState = { isAdmin: false, isSignedIn: false, isGov: false };
            this.setState({ isAuthorized: this.isAdmin() || this.isGov() });

            return;
        }

        const userRoles: DocumentSnapshot | void = await firebase.app().firestore().collection('users').doc(user.uid).collection('ProfileData').doc('Roles').get().catch(console.error);

        const isAdmin: boolean = Boolean(userRoles && userRoles.data()?.IsAdmin) || false;
        const isGov: boolean = Boolean(userRoles && userRoles.data()?.IsGov) || false;

        this.privateState = { isAdmin, isSignedIn: true, isGov };

        this.setState({ isAuthorized: this.isAdmin() || this.isGov() });
    }

    private registerAuthStateChangeCallbacks(): void
    {
        this.unsubscribeHandler = firebase.app().auth().onAuthStateChanged(this.handleUserAuthStateChanged.bind(this));
    }

    componentDidMount(): void
    {
        this.registerAuthStateChangeCallbacks();
    }

    componentWillUnmount(): void
    {
        if (this.unsubscribeHandler != null) this.unsubscribeHandler();
    }

    private isAdmin(): boolean
    {
        return this.privateState.isSignedIn && this.privateState.isAdmin;
    }

    private isGov(): boolean
    {
        return this.privateState.isSignedIn && this.privateState.isGov;
    }

    render(): JSX.Element
    {
        return (
            <Router>
                <Switch>
                    <Route path="/"> {this.isGov() ? <PaperbaseGovWithStyle /> : this.isAdmin() ? <Paperbase/> : <SignIn />} </Route>
                </Switch>
            </Router>
        );
    }
}
