import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StateHook } from '../../Types';
import { SupportedLanguagesTags } from './LocalizationComponent';
import { TranslationCache } from './LocalizationContainer';
import LocalizationTranslationInput from './LocalizationTranslationInput';

const formElements: { name: SupportedLanguagesTags, type: string, defaultValue: string; }[] = [
    {
        name: 'en-US',
        type: 'text',
        defaultValue: 'eg. English Translation'
    },
    {
        name: 'pt-PT',
        type: 'text',
        defaultValue: 'eg. Portuguese Translation'
    }
];

type LocalizationEditorPropsType = {
    onTranslationUpdated: (translationKey: string, languageTag: SupportedLanguagesTags, translationText: string) => void;
    translationKey: string;
};

function LocalizationEditorComponent(props: LocalizationEditorPropsType): JSX.Element
{
    const [translationKey, setTranslationKey]: StateHook<string> = useState('');

    useEffect(() => setTranslationKey(props.translationKey || ''), [props.translationKey]);

    return (<>
        <Grid item xs={12} key="key">
            <TextField
                type="text"
                label="Key"
                fullWidth={true}
                value={translationKey}
                placeholder="eg. Unique Identifier"
                onChange={ev => setTranslationKey(ev.target.value.trim().toLowerCase())}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        </Grid>

        {formElements.map((element, index) =>
        {
            return <LocalizationTranslationInput
                key={`${index + 29999}`}
                initialTranslation={TranslationCache[props.translationKey]?.[element.name] || ''}
                language={element.name}
                translationKey={translationKey}
                onTranslationUpdated={(languageTag, translationText) => props.onTranslationUpdated(translationKey, languageTag, translationText)}
            />;
        })}
    </>
    );
}

export default LocalizationEditorComponent;
