import React, { ChangeEvent } from 'react';
import { Container, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { firebase, deployRegion } from '../Firebase';
import { DocumentSnapshot, HttpsCallable } from '../Types';
import 'firebase/remote-config';

type DisplayVariable = {
    currentValue: string;
    initialValue: string;
};
interface IState
{
    earningTaxPercentage: DisplayVariable;
    houseWinningPercentage: DisplayVariable;
    ivaTaxPercentage: DisplayVariable;
    retentionTaxPercentage: DisplayVariable;
    taxOnCapitalGainPercentage: DisplayVariable;
}


export default class TaxesConfigurationComponent extends React.Component<unknown, IState> {

    private labels: { [key: string]: string; } = {
        earningTaxPercentage: 'Earning Tax Percentage',
        houseWinningPercentage: 'House Winning Percentage',
        ivaTaxPercentage: 'Iva Tax Percentage',
        retentionTaxPercentage: 'Retention Tax Percentage',
        taxOnCapitalGainPercentage: 'Tax on Capital Gain Percentage'
    };

    state: IState = {
        earningTaxPercentage: { currentValue: '', initialValue: '' },
        houseWinningPercentage: { currentValue: '', initialValue: '' },
        ivaTaxPercentage: { currentValue: '', initialValue: '' },
        retentionTaxPercentage: { currentValue: '', initialValue: '' },
        taxOnCapitalGainPercentage: { currentValue: '', initialValue: '' }
    };

    onValueChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        const targetValue: string = event.target.value;
        const targetProperty: keyof IState = event.target.name as keyof IState;

        const value: DisplayVariable = {
            currentValue: targetValue,
            initialValue: this.state[targetProperty].initialValue
        };

        this.setState({ [targetProperty]: value } as Pick<IState, keyof IState>);
    }


    async saveValues(): Promise<void>
    {
        console.log('Saving values...', JSON.stringify(this.state));

        const setActiveTaxes: HttpsCallable = firebase.app().functions(deployRegion).httpsCallable('api/admin/create-active-tax-values');

        const newTaxes: { [key: string]: number; } = {};

        for (const [taxName, taxValues] of Object.entries(this.state) as [keyof IState, DisplayVariable][])
            newTaxes[taxName] = Number.parseFloat(taxValues.currentValue) / 100;


        if ((await setActiveTaxes(newTaxes).catch(console.error)))
        {
            console.log('Tax values saved');

            const newState: IState = {} as IState;

            for (const [name, value] of Object.entries(this.state) as [keyof IState, DisplayVariable][])
                newState[name] = {
                    currentValue: value.currentValue,
                    initialValue: value.currentValue
                };

            this.setState(newState);
        }
    }

    async getActiveTaxesReferenceId(): Promise<string | undefined>
    {
        const activeTierDocument: DocumentSnapshot | void = await firebase.firestore().collection('active_tax_percentages').doc('ActiveTaxes').get().catch(console.error);

        if (!activeTierDocument || !activeTierDocument.exists)
            return;

        return activeTierDocument.data()?.taxesId;
    }

    async getCurrentTaxes(tierDocumentId: string): Promise<{ [key: string]: number; } | undefined>
    {
        const targetTierDocument: DocumentSnapshot | void = await firebase.firestore().collection('tax_percentages').doc(tierDocumentId).get().catch(console.error);

        if (!targetTierDocument || !targetTierDocument.exists || !targetTierDocument.data())
            return;

        const taxes: firebase.firestore.DocumentData | undefined = targetTierDocument.data();

        if (!taxes)
            return;

        delete taxes.createdAt;
        return taxes;
    }

    async setInitialState(): Promise<void>
    {
        const tierDocumentId: string | undefined = await this.getActiveTaxesReferenceId();

        if (!tierDocumentId)
            return;

        const taxes: { [key: string]: number; } | undefined = await this.getCurrentTaxes(tierDocumentId);

        if (!taxes)
            return;

        const initialState: IState = {} as IState;

        for (const [name, value] of Object.entries(taxes) as [keyof IState, number][])
            initialState[name] = {
                currentValue: String((Math.floor(value * 100000)) / 1000),
                initialValue: String((Math.floor(value * 100000)) / 1000)
            };

        this.setState(initialState);
    }

    async componentDidMount(): Promise<void>
    {
        await this.setInitialState();
    }


    render(): JSX.Element
    {
        return (
            <Container component="main" style={{ width: '50vh' }}>
                {
                    Object.keys(this.state).map((property, index) =>
                    {
                        return <TextField
                            key={index}
                            id={property}
                            name={property as keyof IState}
                            label={this.labels[property]}
                            value={this.state[property as keyof IState].currentValue}
                            variant="outlined"
                            onChange={this.onValueChange.bind(this)}
                            fullWidth
                            type="number"
                            style={{ marginBottom: '1em' }}
                        />;
                    })
                }
                <Button type="submit" fullWidth variant="contained" color="primary" onClick={this.saveValues.bind(this)}>SAVE</Button>
            </Container >
        );
    }
}

