import React, { ChangeEvent } from 'react';
import {Container, Button, Box, Grid} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { firebase } from '../Firebase';
import { DocumentSnapshot } from '../Types';
import 'firebase/remote-config';

type DisplayVariable = {
    currentValue: string;
    initialValue: string;
};
interface IState
{
    botValues: Map<number, DisplayVariable>;
}


export default class BotConfiguration extends React.Component<unknown, IState> {
    state: IState = {
        botValues: new Map<number, DisplayVariable>()
    };

    onBotValueChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void {
        const map: Map<number, DisplayVariable> = this.state.botValues;

        const value:number | undefined = parseInt(event.target.value, 10) | 0;
        
        if (map.has(index)) {
            map.get(index)!.currentValue = value.toString();
        } else {
            map.set(index, {currentValue: value.toString(), initialValue: value.toString()})
        }

        console.log(`[onBotValueChanged] set key index ${index} to value ${event.target.value}`)

        this.setState({
            botValues: map
        });
    }

    async saveValues(): Promise<void> {
        console.log('Saving values...', JSON.stringify(this.state));
        const values: DisplayVariable[] = Array.from(this.state.botValues.values());
        if (values.some(value => value.initialValue !== value.currentValue)) {
            console.log('Change detected, lets save!');
            const newBotConfig: number[] = [];
            values.forEach(value => newBotConfig.push(+value.currentValue));

            const botConfigDocument: DocumentSnapshot | void = await firebase.firestore().collection('bot_configurations').doc('active_configuration').get().catch(console.error);
            if (!botConfigDocument || !botConfigDocument.exists) return;

            console.log('Update bot config');

            await firebase.firestore().collection('bot_configurations').doc('active_configuration').update({
                botNumbers: newBotConfig
            }).catch(console.error);
        }
    }

    async componentDidMount(): Promise<void> {
        await Promise.all([
            firebase.remoteConfig().fetchAndActivate(),
            this.fetchBotConfig(),
        ]);
    }

    async fetchBotConfig(): Promise<void> {
        const botConfigDocument: DocumentSnapshot | void = await firebase.firestore().collection('bot_configurations').doc('active_configuration').get().catch(console.error);
        if (!botConfigDocument || !botConfigDocument.exists) return;
        const arr: number[] = botConfigDocument.data()?.botNumbers!;
        const map: Map<number, DisplayVariable> = new Map<number, DisplayVariable>();
        arr.forEach((value, index) => {
            map.set(index, {
                currentValue: value.toString(),
                initialValue: value.toString()
            });
        })
        this.setState({
            botValues: map
        });
    }

    render(): JSX.Element {
        const rows = [];
        const numBets: number = 200;
        const columnSize: number = 8;
        const numRows: number = Math.ceil(numBets / columnSize);

        const drawElement = (index: number) => {
            return <React.Fragment>
                {/*<Grid item xs={1}>
                    <TextField
                        disabled
                        id="filled-disabled"
                        label={` `}
                        type="number"
                        value={index}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>*/}
                <Grid item xs={8}>
                    <TextField
                        id="outlined-number"
                        label={`Real Players ${index}`}
                        type="number"
                        value={this.state.botValues.get(index)!?.currentValue ?? 0}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            style: {
                                color: (Math.floor((index) / (numRows)) % 2 == 0 ? "#000000" : "#000000"),
                                width: "auto",
                                padding: "8px"
                            },
                            inputProps: {
                                max: 200, min: 0
                            }
                        }}
                        variant={"outlined"}
                        size={"small"}
                        margin={"dense"}
                        color={(Math.floor((index) / (numRows)) % 2 == 0 ? "primary" : "secondary")}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.onBotValueChanged(event, index)}
                        focused
                    />
                </Grid>
                <Grid item/>
            </React.Fragment>
        }
        const drawColumn = (offset: number) => {
            const arr: JSX.Element[] = [];
            for (let i = 0; i < columnSize; i++) {
                const index: number = offset + i * numRows;
                if (index > numBets) continue;
                arr.push(<React.Fragment>
                    {drawElement(index)}
                </React.Fragment>)
            }
            return <React.Fragment>
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"row"} wrap={"nowrap"} style={{minHeight: '100%', minWidth: '80%'}}>
                        {arr}
                    </Grid>
                </Box>
            </React.Fragment>;
        }

        for (let i = 0; i < numRows + 1; i++) {
            rows.push(
                drawColumn(i)
            );
        }

        return (
            <Container component="main" style={{minHeight: '90%', minWidth: '100%'}}>
                <Grid container direction={"column"} wrap={"nowrap"} style={{minHeight: '100%', minWidth: '80%'}}>
                    {rows}
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary"
                        onClick={this.saveValues.bind(this)}>SAVE</Button>
            </Container>
        );
    }
}
