import React from 'react';
import { Button, Grid, Modal, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { firebase } from '../Firebase';
import { HttpsCallable, HttpsCallableResult } from '../Types';
import { User } from './UserApprovalComponent';




interface IProps
{
    currentInformation: User;
    OnUserDeleteError: () => void;
    StartEditEvent: () => void;
    OnUserDeleted: (userId: string) => void;
}

export default class UserDetails extends React.Component<IProps, User & { isModalOpen: boolean; }> {
    state: User & { isModalOpen: boolean; } = {
        address: '',
        bankAccount: '',
        bankName: '',
        bankSwiftCode: '',
        birthDate: new Date(0),
        email: '',
        fullName: '',
        phoneNumber: '',
        taxNumber: '',
        playerId: '',
        balance: 0,
        verificationDocument: '',
        verified: 0,
        totalAmountOfMoneyPlayed: 0,
        totalAmountOfMoneyWon: 0,
        isModalOpen: false
    };

    public async setUserDetails(data: User)
    {
        this.setState(Object.assign({
            address: '',
            bankAccount: '',
            bankName: '',
            bankSwiftCode: '',
            birthDate: new Date(0),
            email: '',
            fullName: '',
            phoneNumber: '',
            taxNumber: '',
            playerId: '',
            balance: 0,
            verificationDocument: '',
            verified: 0,
            totalAmountOfMoneyPlayed: 0,
            totalAmountOfMoneyWon: 0,
            isModalOpen: this.state.isModalOpen
        }, data));
    }

    componentDidMount()
    {
        this.setUserDetails(this.props.currentInformation);
    }

    private gridStyle = { height: '2em' };

    private async deleteUser(playerId: string): Promise<void>
    {
        this.setState({ isModalOpen: false });

        const getPlayersInformation: HttpsCallable = firebase.app().functions('europe-west3').httpsCallable('api/admin/delete-user-account');

        const result: HttpsCallableResult = await getPlayersInformation({ targetPlayerId: playerId }).catch(console.error);

        console.log('User deletion result ', JSON.stringify(result));

        if (result)
            this.props.OnUserDeleted(playerId);
        else
            this.props.OnUserDeleteError();
    }

    render()
    {
        return (
            <Paper style={{ padding: '1em' }}>
                <Grid container >
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Full Name : {this.state.fullName} </p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <p>Phone Number : {this.state.phoneNumber} </p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Email Address : {this.state.email}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <p>Address : {this.state.address}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Birth Date : {this.state.birthDate?.toISOString?.().split('T')[0] || this.state.birthDate || ''}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <p>Tax Number : {this.state.taxNumber}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Bank Name : {this.state.bankName}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <p>Bank Account : {this.state.bankAccount}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Balance : {this.state.balance}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <p>Money played : {this.state.totalAmountOfMoneyPlayed}</p>
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <p>Money won : {this.state.totalAmountOfMoneyWon}</p>
                    </Grid>
                    <Grid style={Object.assign({ marginBottom: '1em' }, this.gridStyle)} item xs={5}>
                        <p>Swift Code : {this.state.bankSwiftCode}</p>
                    </Grid>
                    <Grid container justify='center' style={Object.assign({ marginBottom: '2em' }, this.gridStyle)} item xs={10}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={!this.state.playerId}
                            onClick={this.props.StartEditEvent}>
                            Edit Profile
                        </Button>
                    </Grid>
                    <Grid container justify='flex-end' style={{ marginLeft: 'auto', height: '2em' }} item xs={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!this.state.playerId}
                            endIcon={<DeleteIcon />}
                            onClick={() => this.setState({ isModalOpen: true })}>
                            Delete
                        </Button>
                        <Modal
                            open={this.state.isModalOpen}
                            onClose={() => this.setState({ isModalOpen: false })}>
                            <div style={{
                                position: 'absolute',
                                width: '40vw',
                                backgroundColor: 'white',
                                top: `${50}%`,
                                left: `${50}%`,
                                transform: `translate(-${50}%, -${50}%)`,
                                boxShadow: '10px',
                                padding: '1em'
                            }} >
                                <h2>Are you sure you want do delete this user?</h2>
                                <Grid container >

                                    <Grid style={this.gridStyle} item xs={5}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disabled={!this.state.email}
                                            fullWidth
                                            onClick={() => this.setState({ isModalOpen: false })}>
                                            NO
                                        </Button>
                                    </Grid>
                                    <Grid style={this.gridStyle} item xs={2}>

                                    </Grid>
                                    <Grid style={this.gridStyle} item xs={5}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            disabled={!this.state.email}
                                            fullWidth
                                            endIcon={<DeleteIcon />}
                                            onClick={() => this.state.playerId && this.deleteUser(this.state.playerId)}>
                                            YES
                                        </Button>
                                    </Grid>
                                </Grid>

                            </div>
                        </Modal>
                    </Grid>

                </Grid>
            </Paper >
        );
    }
}
