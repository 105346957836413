import React from 'react';
import { createMuiTheme, createStyles, ThemeProvider, withStyles, WithStyles, } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator, {NavigatorGovWithStyle} from './Navigator';
import Content from './Content';
import Header from './Header';
import UserApproval from './components/UserApprovalComponent';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import GameConfiguration from './components/GameConfigurationComponent';
import TaxesConfigurationComponent from './components/TaxesConfigurationComponent';
import LocalizationContainer from './components/localization/LocalizationContainer';
import StatusComponent from './components/StatusComponent';
import GamesListing from './components/MatchesList';
import BotConfiguration from "./components/BotConfigurationComponent";
import UserTransactions from "./components/UserTransactionHistory";
import UserLogout from "./components/UserLogout";
 

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const drawerWidth = 256;

const styles = createStyles({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 1),
        background: '#eaeff1',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
});

export type PaperbaseProps = WithStyles<typeof styles>;

function Paperbase(props: PaperbaseProps)
{
    const { classes } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const { path } = useRouteMatch();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Navigator PaperProps={{ style: { width: drawerWidth } }} />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <main className={classes.main}>
                        <Switch>
                            
                            <Route exact path={`${path}users`} component={UserApproval} />
                            <Route exact path={`${path}game-configuration`} component={GameConfiguration} />
                            <Route exact path={`${path}bot-configuration`} component={BotConfiguration} />
                            <Route exact path={`${path}transactions`} component={UserTransactions} />
                            <Route exact path={`${path}taxes`} component={TaxesConfigurationComponent} />
                            <Route exact path={`${path}matches`} component={GamesListing} />
                            <Route exact path={`${path}localization`} component={LocalizationContainer} />
                            <Route exact path={`${path}status`} component={StatusComponent} />
                            <Route exact path={`${path}logout`} component={UserLogout} />
                            <Route path={path} component={Content} />
                        </Switch>
                    </main>
                    <footer className={classes.footer}>
                    </footer>
                </div>
            </div>
        </ThemeProvider>
    );
}

function PaperbaseGov(props: PaperbaseProps)
{
    const { classes } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const { path } = useRouteMatch();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <NavigatorGovWithStyle
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <NavigatorGovWithStyle PaperProps={{ style: { width: drawerWidth } }} />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <main className={classes.main}>
                        <Switch>
                            <Route exact path={`${path}matches`} component={GamesListing} />
                            <Route exact path={`${path}transactions`} component={UserTransactions} />
                            <Route exact path={`${path}logout`} component={UserLogout} />
                            <Route path={path} component={Content} />
                        </Switch>
                    </main>
                    <footer className={classes.footer}>
                    </footer>
                </div>
            </div>
        </ThemeProvider>
    );
}

export const PaperbaseGovWithStyle = withStyles(styles)(PaperbaseGov);

export default withStyles(styles)(Paperbase);
