import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { StateHook } from '../../Types';
import LocalizationEditorComponent from './LocalizationEditorFormComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SupportedLanguagesTags, TranslationLanguages } from './LocalizationComponent';
import { TranslationCache } from './LocalizationContainer';

function onTranslationTextUpdated(translationKey: string, languageTag: SupportedLanguagesTags, translationText: string): void
{
    if (!translationKey)
        return;

    console.log(translationKey, languageTag, translationText);

    const currentTranslation: TranslationLanguages = TranslationCache[translationKey] || { 'en-US': '', 'pt-PT': '' };

    currentTranslation[languageTag] = translationText;

    TranslationCache[translationKey] = currentTranslation;
}

function TranslationForm(props: { saveTranslations: () => void; selectedKey: string; isPanelExtended: boolean; togglePanelExpended: () => void; }): JSX.Element
{
    const [isPanelExpended, setPanelExpended]: StateHook<boolean> = useState(false as boolean);
    const [selectedKey, setSelectedKey]: StateHook<string> = useState('');

    useEffect(() => setSelectedKey(props.selectedKey || ''), [props.selectedKey]);
    useEffect(() => setPanelExpended(props.isPanelExtended), [props.isPanelExtended]);

    return (
        <Accordion expanded={isPanelExpended}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => props.togglePanelExpended()}>
                <Typography className='heading'>Add Translations</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={e => { e.preventDefault(); props.saveTranslations(); }}>
                    <Grid container spacing={1}>
                        <LocalizationEditorComponent
                            onTranslationUpdated={onTranslationTextUpdated}
                            translationKey={selectedKey} />
                        <Grid item xs={12}>
                            <Button
                                fullWidth={true}
                                type="submit"
                                variant="contained"
                                color="primary">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default TranslationForm;
