import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core';
import { ActiveMatchResult } from './MatchesList';
import firebase from 'firebase';
import { DocumentSnapshot, HttpsCallable, HttpsCallableResult, QuerySnapshot } from '../Types';
import { Transaction } from './UserTransactionHistory';
import { BasicUser } from './UserApprovalComponent';

export function UsersParticipantsButtonWithModal(props: { match: ActiveMatchResult; })
{
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [users, setUsers] = useState([] as BasicUser[]);

    const InitializeUsers = async (users: string[]) =>
    {
        if (users.length > 0)
        {
            const getPlayersInformation: HttpsCallable = firebase.app().functions('europe-west3').httpsCallable('api/admin/batch-user-information');

            const result: HttpsCallableResult = await getPlayersInformation({ targetPlayerIdList: users }).catch(console.error);

            if (result && result.data && result.data.users)
                setUsers(result.data.users);
        }
    };

    const initialize = async () =>
    {
        const snapshot: QuerySnapshot | null = await firebase.app().firestore().collection('transactions').where('matchId', '==', props.match.id).get().catch((error) => { console.error(error); return null; });

        const transactions: Transaction[] = snapshot?.docs.map((document: DocumentSnapshot) => document.data() as Transaction) || [];

        const usersSet: Set<string> = new Set(transactions.map((transaction: Transaction) => transaction.playerId));

        const users: string[] = Array.from(usersSet);

        await InitializeUsers(users);

        setIsInitialized(true);
    };

    useEffect(() =>
    {
        if (isPopupOpen && !isInitialized)
            initialize().catch(console.error);

    }, [isPopupOpen, isInitialized]);


    return <div>
        <Button variant="outlined" color="primary" onClick={() => setIsPopupOpen(true)}>Show Participants</Button>
        <Dialog maxWidth="md" fullWidth open={isPopupOpen} onClose={() => setIsPopupOpen(false)} scroll={'paper'}>
            <DialogTitle> Users </DialogTitle>

            <DialogContent dividers>
                <Grid container>
                    {users.map((user: BasicUser, index: number) => (
                        <Grid item xs={12} key={index}>
                            <Box p={1}>
                                <Grid container direction="row" justify="center">
                                    <Grid item xs={10} container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Typography style={{ marginLeft: '0.5em' }}> {user.fullName} </Typography>
                                        <Typography style={{ marginLeft: '0.5em' }}> {user.phoneNumber} </Typography>
                                        <Typography style={{ marginLeft: '0.5em' }}> {user.email} </Typography>
                                        <Typography style={{ marginLeft: '0.5em' }}> Id: {user.playerId} </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" onClick={() =>
                                        {
                                            const newWindow: Window | null = window.open(`${window.location.origin}/transactions?user=${user.playerId}`, '_blank', 'noopener, noreferrer');

                                            if (newWindow)
                                                newWindow.opener = null;
                                        }}>All transactions</Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    </div>;


}
