import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { deployRegion, firebase } from '../Firebase';
import React, { useEffect, useState } from 'react';
import { HttpsCallable, HttpsCallableResult } from '../Types';

async function AreActiveChrons(): Promise<boolean>
{
    const Call: HttpsCallable = firebase.app().functions(deployRegion).httpsCallable('api/admin/active-chrons');

    const result: HttpsCallableResult | null = await Call().catch((error) => { console.error(error); return null; });

    const tasks: null | { taskPages: null | { name: string; }[][]; } = result?.data;

    console.log(JSON.stringify({ tasks }));

    if (!tasks || !tasks?.taskPages?.length)
        return false;

    return tasks.taskPages.length > 0 && tasks.taskPages[0]?.length > 0;
}

export default function StatusComponent()
{
    const [isChronJobActive, setIsChronJobActive] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const GetData = () =>
    {
        setLoading(true);
        AreActiveChrons().then((result: boolean) =>
        {
            setLoading(false);
            setIsChronJobActive(result);
        });
    };

    const RefreshChron = async () =>
    {
        setLoading(true);
        const Call: HttpsCallable = firebase.app().functions(deployRegion).httpsCallable('chronJobCallBack');

        await Call().catch(error => { console.error(error); });

        setLoading(false);
    };

    useEffect(() =>
    {
        GetData();
        const timer: NodeJS.Timeout = setInterval(() => GetData(), 30 * 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Paper style={{ padding: '3em' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Current Status: {isLoading ? 'Loading...' : isChronJobActive ? 'Online!' : 'Error!'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" disabled={isLoading || isChronJobActive} onClick={RefreshChron}>Restart</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
