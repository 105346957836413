import React from 'react';
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Delete';

type LocalizationKeyProps = {
    translationKey: string;
    onTranslationKeySelect: (translationKey: string) => void;
    onTranslationKeyDelete: (translationKey: string) => void;
};
function LocalizationKey(props: LocalizationKeyProps): JSX.Element
{
    return (
        <div key={props.translationKey}>
            <ListItem button onClick={() => props.onTranslationKeySelect(props.translationKey)}>
                <ListItemAvatar>
                    <Avatar>
                        <TranslateIcon color="primary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.translationKey} />
                <ListItemSecondaryAction>
                    <IconButton aria-label="Edit" onClick={() => props.onTranslationKeyDelete(props.translationKey)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </div>
    );
}

export default LocalizationKey;
