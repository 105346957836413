import React, { createRef, FormEvent } from 'react';
import { Container, Paper, Button, TextField, CircularProgress, Chip } from '@material-ui/core';
import { DataGrid, ColDef, RowData, ValueFormatterParams, RowParams, CellParams } from '@material-ui/data-grid';
import { firebase } from '../Firebase';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import UserDetails from './UserDetailsComponent';
import { HttpsCallable, HttpsCallableResult, QuerySnapshot } from '../Types';
import UpdateUserDetailsComponent from './UpdateUserInformationComponent';
import { RouteComponentProps } from 'react-router-dom';


interface IState
{
    rows: RowData[];
    emailFilter: string;
    playerNameFilter: string;
    selectedUserId: string;
    playerPhoneFilter: string;
    loadingPercentage: number;
    isEditing: boolean;
    showErrorOnSave: boolean;
    showSuccessOnSave: boolean;
}

export enum EPlayerVerificationStatus
{
    UNVERIFIED = 0,
    VERIFICATION_IN_PROGRESS = 1,
    APPROVED = 2,
    REJECTED = 3
}

type VerificationStatus = { verificationDocument: string, verified: number; };

export type BasicUser = {
    fullName?: string,
    address?: string,
    birthDate?: Date,
    bankAccount?: string,
    bankSwiftCode?: string,
    taxNumber?: string,
    phoneNumber?: string,
    email?: string,
    bankName?: string;
    playerId?: string;
    balance: number;
    totalAmountOfMoneyPlayed?: number;
    totalAmountOfMoneyWon?: number;
};

export type User = BasicUser & VerificationStatus;

type UserVerificationMap = { [userId: string]: VerificationStatus; };


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class UserApproval extends React.Component<RouteComponentProps<any>, IState> {
    private PlayerVerificationStatusStrings: Map<number, string> = new Map(
        [
            [0, 'Not Verified'],
            [1, 'Verification in progress'],
            [2, 'Approved'],
            [3, 'Rejected']
        ]
    );

    private static UserCache: { [userId: string]: User; } = {};

    private userDetailsRef: React.RefObject<UserDetails> = createRef<UserDetails>();

    columns: ColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'email', headerName: 'Email', width: 350 },
        { field: 'name', headerName: 'Name', width: 170 },
        { field: 'phone', headerName: 'Phone', width: 150 },
        { field: 'balance', headerName: 'Balance', width: 100 },
        {
            field: 'document', headerName: 'Document', width: 100, renderCell: (params: ValueFormatterParams): JSX.Element => (
                <Button variant='text' color='primary' size='large' onClick={() => this.onDownloadDocumentButtonClicked(params)}>{params.value}</Button>
            )
        },
        {
            field: 'verification_status', headerName: 'Status', width: 150, valueFormatter: (params: ValueFormatterParams) => this.PlayerVerificationStatusStrings.get(params.value as number)
        },
        {
            field: 'approve', headerName: 'Approve', width: 120, renderCell: (params: ValueFormatterParams): JSX.Element => (
                params.getValue('verification_status') === 1 ?
                    <Button variant='contained' color='primary' size='large' onClick={() => this.onApprovalButtonClicked(params.value as string)}>Approve</Button> :
                    <Button variant='contained' color='primary' size='large' disabled={true}>Approve</Button>
            )
        },
        {
            field: 'reject', headerName: 'Reject', width: 120, renderCell: (params: ValueFormatterParams): JSX.Element => (
                params.getValue('verification_status') === 1 ?
                    <Button variant='contained' color='secondary' size='large' onClick={() => this.onRejectionButtonClicked(params.value as string)}>Reject</Button> :
                    <Button variant='contained' color='secondary' size='large' disabled={true}>Reject</Button>
            )
        },
        {
            field: 'transactions', headerName: 'Transactions', width: 250, renderCell: (params: ValueFormatterParams): JSX.Element => (
                params.getValue('id') ?
                    // <Button variant='contained' color='primary' size='large' onClick={() => window.open(`/transactions?user=${params.getValue('id')}`, '_blank')}>Transactions</Button> :
                    <Button variant='contained' color='primary' size='large' onClick={() => this.props.history.push(`/transactions?user=${params.getValue('id')}`)} >Transactions</Button> :
                    <Button variant='contained' color='secondary' size='large' disabled={true}>Transactions</Button>
            )
        },
    ];

    state: IState = {
        rows: [],
        emailFilter: '',
        playerNameFilter: '',
        playerPhoneFilter: '',
        selectedUserId: '',
        loadingPercentage: 0,
        showSuccessOnSave: false,
        showErrorOnSave: false,
        isEditing: false
    };

    async onDownloadDocumentButtonClicked(params: CellParams): Promise<void>
    {
        const getDownloadURL: HttpsCallable = firebase.app().functions('europe-west3').httpsCallable('api/admin/user-verification-document');
        const result: HttpsCallableResult | void = await getDownloadURL({ targetPlayerId: params.getValue('id') }).catch(console.error);

        if (result && result.data)
            window.open(result.data.url);
    }

    onApprovalButtonClicked(userId: string): void
    {
        console.log('approving user', userId);
        firebase.app().firestore().collection('users').doc(userId).update({ verified: EPlayerVerificationStatus.APPROVED }).then(() =>
        {
            UserApproval.UserCache[userId].verified = EPlayerVerificationStatus.APPROVED;
            this.createOrUpdateRowsFromCache();
        }).catch(console.error);
    }

    onRejectionButtonClicked(userId: string): void
    {
        console.log('rejecting user', userId);
        firebase.app().firestore().collection('users').doc(userId).update({ verified: EPlayerVerificationStatus.REJECTED }).then(() =>
        {
            UserApproval.UserCache[userId].verified = EPlayerVerificationStatus.REJECTED;
            this.createOrUpdateRowsFromCache();

        }).catch(console.error);
    }

    async retrieveAllUsersData(): Promise<void>
    {
        const results: QuerySnapshot | void = await firebase.app().firestore().collection('users').get().catch(console.error);

        if (!results)
            return;

        const users: UserVerificationMap = {};

        for (const document of results.docs)
            if (document.exists && document.data())
                users[document.id] = {
                    verificationDocument: document.data().verificationDocument || '',
                    verified: document.data().verified || false
                };

        await this.populateRowsWithUsers(users);
    }

    async populateRowsWithUsers(targetPlayerVerificationList: UserVerificationMap): Promise<void>
    {
        this.setState({ loadingPercentage: 0 });

        const getPlayersInformation: HttpsCallable = firebase.app().functions('europe-west3').httpsCallable('api/admin/batch-user-information');

        const result: HttpsCallableResult = await getPlayersInformation({ targetPlayerIdList: Object.keys(targetPlayerVerificationList) }).catch(console.error);

        if (result && result.data && result.data.users)
            await this.populateUserCacheWithEntry(result.data.users, targetPlayerVerificationList);

        this.setState({ loadingPercentage: 100 });
    }

    async populateUserCacheWithEntry(retrievedUserInformation: BasicUser[], userVerification: UserVerificationMap): Promise<void>
    {
        for (const userInformation of retrievedUserInformation)
        {
            if (!userInformation.playerId)
                continue;

            UserApproval.UserCache[userInformation.playerId] = {
                ...userInformation,
                verificationDocument: userVerification[userInformation.playerId].verificationDocument,
                verified: userVerification[userInformation.playerId].verified
            };

            if (typeof (userInformation.birthDate as unknown as { _seconds: number; })?._seconds === 'number')
                UserApproval.UserCache[userInformation.playerId].birthDate = new Date((userInformation.birthDate as unknown as { _seconds: number; })._seconds * 1000);
        }
    }

    createOrUpdateRowsFromCache(): void
    {
        const currentRows: RowData[] = [];

        for (const [userId, userData] of Object.entries(UserApproval.UserCache))
            currentRows.push({
                id: userId || '',
                phone: userData.phoneNumber || '',
                balance: userData.balance || '',
                document: userData.verificationDocument || '',
                verification_status: userData.verified || 0,
                approve: userId || '',
                reject: userId || '',
                email: userData.email || '',
                name: userData.fullName || '',
                transactions: userId
            });

        this.setState({ rows: currentRows });
    }

    async componentDidMount(): Promise<void>
    {
        if (Object.keys(UserApproval.UserCache).length === 0)
            await this.retrieveAllUsersData();
        else
            this.setState({ loadingPercentage: 100 });

        this.createOrUpdateRowsFromCache();
    }


    async refreshData(event: React.FormEvent<HTMLFormElement>): Promise<void>
    {
        event.preventDefault();

        this.setState({ rows: [] });

        await this.retrieveAllUsersData();
        this.createOrUpdateRowsFromCache();
    }

    private rowSelected(rowParams: RowParams): void
    {
        this.setState({ selectedUserId: rowParams.getValue('id') as string });

        console.log(JSON.stringify(UserApproval.UserCache[this.state.selectedUserId]));

        if (UserApproval.UserCache[this.state.selectedUserId])
            if (this.userDetailsRef.current != null) this.userDetailsRef.current.setUserDetails({ ...UserApproval.UserCache[this.state.selectedUserId], playerId: this.state.selectedUserId }
            );
    }

    private filterRows(event: FormEvent<HTMLFormElement>): void
    {
        if (event != null) event.preventDefault();


        const emailFilter: string = this.state.emailFilter.trim().toLowerCase();
        const playerNameFilter: string = this.state.playerNameFilter.trim().toLowerCase();
        const playerPhoneFilter: string = this.state.playerPhoneFilter.trim();

        if (!emailFilter && !playerNameFilter && !playerPhoneFilter)
            return this.createOrUpdateRowsFromCache();

        const currentRows: RowData[] = [];

        for (const [userId, userData] of Object.entries(UserApproval.UserCache))
            if (this.isUserMatchForFilter(userData, emailFilter, playerNameFilter, playerPhoneFilter))
                currentRows.push({
                    id: userId || '',
                    phone: userData.phoneNumber || '',
                    document: userData.verificationDocument || '',
                    verification_status: userData.verified || 0,
                    approve: userId || '',
                    reject: userId || '',
                    email: userData.email || '',
                    name: userData.fullName || '',
                    transactions: userId
                });

        this.setState({ rows: currentRows });
    }

    private isUserMatchForFilter(userData: User, emailFilter: string, playerNameFilter: string, playerPhoneFilter: string)
    {
        console.log(playerNameFilter);

        return (((emailFilter && userData.email?.toLowerCase()?.includes(emailFilter))
            || (playerNameFilter && userData.fullName?.toLowerCase()?.includes(playerNameFilter))
            || (playerPhoneFilter && userData.phoneNumber?.includes(playerPhoneFilter))));
    }

    private resetFilter(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
    {
        if (event != null) event.preventDefault();

        this.setState({
            emailFilter: '',
            playerNameFilter: '',
            playerPhoneFilter: ''
        });

        this.createOrUpdateRowsFromCache();
    }

    private startEditing(): void
    {
        this.setState({ showErrorOnSave: false, showSuccessOnSave: false, isEditing: true });
    }

    private cancelEditing(): void
    {
        this.setState({ isEditing: false, showErrorOnSave: false, showSuccessOnSave: false });
    }

    private async saveUpdatedUserInformation(data: User): Promise<void>
    {
        if (!data.playerId)
            return console.log('Missing player id!!!');

        this.setState({ showErrorOnSave: false, showSuccessOnSave: true });

        const updateData: Record<string, string | Date> = {};

        if (data.address)
            updateData.address = data.address;

        if (data.bankAccount)
            updateData.bankAccount = data.bankAccount;

        if (data.fullName)
            updateData.fullName = data.fullName;

        if (data.birthDate)
            updateData.birthDate = data.birthDate;

        if (data.bankSwiftCode)
            updateData.bankSwiftCode = data.bankSwiftCode;

        if (data.taxNumber)
            updateData.taxNumber = data.taxNumber;

        if (data.phoneNumber)
            updateData.phoneNumber = data.phoneNumber;

        const result = await firebase.firestore().collection('users').doc(data.playerId).collection('ProfileData').doc('PersonalInformation').set(updateData, { merge: true }).catch(error => { console.error(error); return { error: error || true }; });

        console.log(result, data.playerId, updateData);

        if (result && result.error)
            return this.showErrorChip();

        this.showSuccessChip();

        Object.assign(UserApproval.UserCache[data.playerId], updateData);

        this.createOrUpdateRowsFromCache();
    }

    private showSuccessChip(showForMilliseconds: number = 3000): void
    {
        this.setState({ showErrorOnSave: false, showSuccessOnSave: true });
        setTimeout(() => this.setState({ showSuccessOnSave: false }), showForMilliseconds);
    }

    private showErrorChip(showForMilliseconds: number = 3000): void
    {
        this.setState({ showErrorOnSave: true, showSuccessOnSave: false });
        setTimeout(() => this.setState({ showErrorOnSave: false }), showForMilliseconds);
    }

    private onUserDeleted(userId: string): void
    {
        this.showSuccessChip();

        delete UserApproval.UserCache[userId];

        this.createOrUpdateRowsFromCache();
    }

    private centryingStyle = { marginTop: '1em', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', display: 'block' };

    render(): JSX.Element
    {
        return (
            <Container component="main" style={{ minHeight: '100vh', minWidth: '90%' }}>
                <Paper>
                    <form noValidate autoComplete="off" onSubmit={this.refreshData.bind(this)}>
                        <Button type="submit" fullWidth variant="contained" color="secondary">Refresh</Button>
                    </form>
                    <form style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px' }} noValidate autoComplete="off" onSubmit={this.filterRows.bind(this)}>
                        <TextField
                            id="email-filter"
                            value={this.state.emailFilter}
                            label="Email"
                            onChange={(event) => this.setState({ emailFilter: event.target.value })} />
                        <TextField
                            id="player-name-filter"
                            value={this.state.playerNameFilter}
                            label="Player Name"
                            onChange={(event) => this.setState({ playerNameFilter: event.target.value })} />
                        <TextField
                            id="phone-filter"
                            value={this.state.playerPhoneFilter}
                            label="Phone Number"
                            type="tel"
                            onChange={(event) => this.setState({ playerPhoneFilter: event.target.value })} />
                        <Button type="submit" size="large" style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px', width: '9vw' }} variant="contained" color="primary">Filter</Button>
                        <Button size="large" style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '20px', width: '9vw' }} variant="contained" color="secondary" onClick={this.resetFilter.bind(this)}>Reset</Button>
                    </form>
                </Paper>
                {
                    this.state.isEditing ?
                        <UpdateUserDetailsComponent
                            currentInformation={{ ...UserApproval.UserCache[this.state.selectedUserId], playerId: this.state.selectedUserId }}
                            CancelEditEvent={this.cancelEditing.bind(this)}
                            SaveEditEvent={this.saveUpdatedUserInformation.bind(this)} />
                        : <UserDetails
                            OnUserDeleted={this.onUserDeleted.bind(this)}
                            OnUserDeleteError={this.showErrorChip.bind(this)}
                            StartEditEvent={this.startEditing.bind(this)}
                            currentInformation={{ ...UserApproval.UserCache[this.state.selectedUserId], playerId: this.state.selectedUserId }}
                            ref={this.userDetailsRef} />
                }
                {this.state.showSuccessOnSave && <Chip style={this.centryingStyle} size="medium" color="primary" label="Success!" deleteIcon={<DoneIcon />} />}
                {this.state.showErrorOnSave && <Chip style={this.centryingStyle} size="medium" color="secondary" label="Something went wrong..." deleteIcon={<ErrorIcon />} />}
                {this.state.loadingPercentage < 100 ? <CircularProgress style={this.centryingStyle} /> : ''}
                <DataGrid autoHeight rows={this.state.rows} columns={this.columns} pageSize={15} onRowClick={this.rowSelected.bind(this)} />
            </Container >
        );
    }
}
