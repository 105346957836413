import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { IFirebaseConfig } from './Types';

const firebaseConfig: IFirebaseConfig = {
    apiKey: 'AIzaSyD1u6cXVRAz82gkHzMi_u4xQ3VK_-axSNI',
    authDomain: 'jogajoga-a50b4.firebaseapp.com',
    databaseURL: 'https://jogajoga-a50b4.firebaseio.com',
    projectId: 'jogajoga-a50b4',
    storageBucket: 'jogajoga-a50b4.appspot.com',
    messagingSenderId: '349652857809',
    appId: '1:349652857809:web:1b98e55f89bd09794726f0',
    measurementId: 'G-NTTVZHE4YL'
};

if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

export const deployRegion = 'europe-west3';

export { firebase };
