import React, { ChangeEvent } from 'react';
import { Container, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { firebase, deployRegion } from '../Firebase';
import { DocumentSnapshot, HttpsCallable } from '../Types';
import 'firebase/remote-config';

type DisplayVariable = {
    currentValue: string;
    initialValue: string;
};
interface IState
{
    betAmount: DisplayVariable;
    numberOfPlayersToStartTheGame: DisplayVariable;
}


export default class GameConfiguration extends React.Component<unknown, IState> {
    state: IState = {
        betAmount: { currentValue: '', initialValue: '' },
        numberOfPlayersToStartTheGame: { currentValue: '', initialValue: '' }
    };

    onBetAmountChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({
            betAmount: {
                currentValue: event.target.value,
                initialValue: this.state.betAmount.initialValue
            }
        });
    }

    onNumberOfPlayersChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({
            numberOfPlayersToStartTheGame: {
                currentValue: event.target.value,
                initialValue: this.state.numberOfPlayersToStartTheGame.initialValue
            }
        });
    }

    async saveValues(): Promise<void>
    {
        console.log('Saving values...', JSON.stringify(this.state));

        if (this.state.betAmount.currentValue.length > 0 && this.state.betAmount.currentValue !== this.state.betAmount.initialValue)
            await this.saveAndUpdateBetAmount();

        if (this.state.numberOfPlayersToStartTheGame.currentValue.length > 0 && this.state.numberOfPlayersToStartTheGame.currentValue !== this.state.numberOfPlayersToStartTheGame.initialValue)
            await this.saveMinimumNmberOfPlayers();
    }

    private async saveAndUpdateBetAmount()
    {
        const setBettingTier: HttpsCallable = firebase.app().functions(deployRegion).httpsCallable('api/admin/create-active-betting-tier');

        const targetValue: number = parseInt(this.state.betAmount.currentValue, 10);

        if (await setBettingTier({ targetAmount: targetValue }).catch(console.error))
        {
            console.log('Betting tier saved');

            this.state.betAmount.initialValue = this.state.betAmount.currentValue;
        }
    }

    private async saveMinimumNmberOfPlayers()
    {
        const setRemoteConfigValue: HttpsCallable = firebase.app().functions(deployRegion).httpsCallable('api/admin/set-remote-config-value');

        const remoteConfigOptions: { remoteConfigKey: string, remoteConfigValue: string; } =
        {
            remoteConfigKey: 'minimum_number_of_bets_to_start_game',
            remoteConfigValue: this.state.numberOfPlayersToStartTheGame.currentValue
        };

        if (await setRemoteConfigValue(remoteConfigOptions).catch(console.error))
        {
            console.log('Minimum number of player saved');

            this.state.numberOfPlayersToStartTheGame.initialValue = this.state.numberOfPlayersToStartTheGame.currentValue;
        }
    }

    async componentDidMount(): Promise<void>
    {
        await Promise.all([
            firebase.remoteConfig().fetchAndActivate(),
            this.setInitialValueForMinimumPlayersRequired(),
            this.setInitialValueForBetAmount()
        ]);
    }

    async setInitialValueForPropertyFromRemoteConfig(propertyName: keyof IState, remoteConfigKey: string): Promise<void>
    {
        await firebase.remoteConfig().ensureInitialized();

        const remoteConfigVariableValue: string = firebase.remoteConfig().getString(remoteConfigKey);

        const variableValues: DisplayVariable = {
            initialValue: remoteConfigVariableValue,
            currentValue: remoteConfigVariableValue
        };

        this.setState({ [propertyName]: variableValues } as Record<keyof IState, DisplayVariable>);
    }
    async setInitialValueForMinimumPlayersRequired(): Promise<void>
    {
        this.setInitialValueForPropertyFromRemoteConfig('numberOfPlayersToStartTheGame', 'minimum_number_of_bets_to_start_game');
    }

    async setInitialValueForBetAmount(): Promise<void>
    {
        const activeTierDocument: DocumentSnapshot | void = await firebase.firestore().collection('active_betting_tier').doc('ActiveTier').get().catch(console.error);

        if (!activeTierDocument || !activeTierDocument.exists)
            return;

        const tierDocumentId: string = activeTierDocument.data()?.tierId;

        if (!tierDocumentId)
            return;

        const targetTierDocument: DocumentSnapshot | void = await firebase.firestore().collection('betting_tiers').doc(tierDocumentId).get().catch(console.error);

        if (!targetTierDocument)
            return;

        this.setState({
            betAmount: {
                currentValue: String(targetTierDocument.data()?.tiers?.default?.amount || ''),
                initialValue: String(targetTierDocument.data()?.tiers?.default?.amount || '')
            }
        });
    }

    render(): JSX.Element
    {
        return (
            <Container component="main" style={{ width: '40vh' }}>
                <TextField
                    label="Bet amount"
                    value={this.state.betAmount.currentValue}
                    helperText="This is the amount of money a player pays when he places a bet"
                    variant="filled"
                    type="number"
                    onChange={this.onBetAmountChange.bind(this)}
                />
                <TextField
                    label="Minimum number of players"
                    value={this.state.numberOfPlayersToStartTheGame.currentValue}
                    helperText="This is the number of player required for the game to start"
                    variant="filled"
                    type="number"
                    onChange={this.onNumberOfPlayersChanged.bind(this)}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" onClick={this.saveValues.bind(this)}>SAVE</Button>
            </Container >
        );
    }
}
