import React from 'react';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { User } from './UserApprovalComponent';

interface IProps
{
    currentInformation: User;
    CancelEditEvent: () => void;
    SaveEditEvent: (data: User) => void;
}

export default class UpdateUserDetailsComponent extends React.Component<IProps, User> {
    state: User = {
        address: '',
        bankAccount: '',
        bankName: '',
        bankSwiftCode: '',
        birthDate: new Date(0),
        email: '',
        fullName: '',
        phoneNumber: '',
        taxNumber: '',
        playerId: '',
        balance: 0,
        verificationDocument: '',
        verified: 0,
        totalAmountOfMoneyPlayed: 0,
        totalAmountOfMoneyWon: 0
    };

    public async setUserDetails(data: User)
    {
        console.log('setting edit state', JSON.stringify(data));

        this.setState(Object.assign({
            address: '',
            bankAccount: '',
            bankName: '',
            bankSwiftCode: '',
            birthDate: new Date(0),
            email: '',
            fullName: '',
            phoneNumber: '',
            taxNumber: '',
            playerId: '',
            balance: 0,
            verificationDocument: '',
            verified: 0,
            totalAmountOfMoneyPlayed: 0,
            totalAmountOfMoneyWon: 0
        }, data));
    }

    componentDidMount()
    {
        this.setUserDetails(this.props.currentInformation);
    }

    private gridStyle = { marginLeft: '1em' };

    render()
    {
        return (
            <Paper>
                <Grid container >
                    <Grid style={this.gridStyle} item xs={5}>
                        <TextField
                            fullWidth
                            label="Full Name"
                            value={this.state.fullName}
                            onChange={(ev) => this.setState({ fullName: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            value={this.state.phoneNumber}
                            onChange={(ev) => this.setState({ phoneNumber: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <TextField
                            fullWidth
                            label="Email Addres"
                            disabled
                            value={this.state.email}
                            onChange={(ev) => this.setState({ email: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <TextField
                            fullWidth
                            label="Address"
                            value={this.state.address}
                            onChange={(ev) => this.setState({ address: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <TextField
                            fullWidth
                            label="Birthday"
                            type='date'
                            value={this.state.birthDate?.toISOString?.().split('T')[0] || '1900-01-01'}
                            onChange={(ev) => this.setState({ birthDate: new Date(ev.target.value) })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <TextField
                            fullWidth
                            label="Tax Number"
                            value={this.state.taxNumber}
                            onChange={(ev) => this.setState({ taxNumber: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={5}>
                        <TextField
                            fullWidth
                            label="Bank Name"
                            value={this.state.bankName}
                            disabled
                            onChange={(ev) => this.setState({ bankName: ev.target.value })} />
                    </Grid>
                    <Grid style={this.gridStyle} item xs={6}>
                        <TextField
                            fullWidth
                            label="Bank Account"
                            value={this.state.bankAccount}
                            onChange={(ev) => this.setState({ bankAccount: ev.target.value })} />
                    </Grid>
                    <Grid style={Object.assign({ marginBottom: '1em' }, this.gridStyle)} item xs={6}>
                        <TextField
                            fullWidth
                            label="Swift Code"
                            value={this.state.bankSwiftCode}
                            onChange={(ev) => this.setState({ bankSwiftCode: ev.target.value })} />
                    </Grid>
                    <Grid container justify='center' style={Object.assign({ marginBottom: '2em' }, this.gridStyle)} item xs={12}>
                        <Grid style={this.gridStyle} item xs={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={() => this.props.SaveEditEvent(this.state)}>
                                Save
                            </Button>
                        </Grid>
                        <Grid style={this.gridStyle} item xs={4}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                onClick={this.props.CancelEditEvent}>
                                Cancel
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>
        );
    }
}
